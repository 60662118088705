import { Box, Button, Drawer, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import restService from "../services/restService";

const Sidebar = ({ setIsAuthenticated }) => {
  const location = useLocation();

  /* eslint-disable no-magic-numbers */
  const getTabValue = (pathname) => {
    switch (pathname) {
      case "/widgets/landing":
        return 0;
      case "/widgets/user-search":
        return 1;
      case "/widgets/featured-content":
        return 2;
      case "/widgets/crossroads-prayers":
        return 3;
      case "/widgets/home-tab-spotlight":
        return 4;
      case "widgets/daily-scripture":
        return 5;
      case "widgets/commitments":
        return 6;
      default:
        return false;
    }
  };

  const [value, setValue] = useState(getTabValue(location.pathname));

  useEffect(() => {
    setValue(getTabValue(location.pathname));
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogout = () => {
    restService.logout(setIsAuthenticated).catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
  };

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: "20rem",
          position: "relative",
          height: "100%",
        },
      }}
      PaperProps={{
        elevation: 1, // Set the elevation to 4
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          padding: "1rem",
        }}
      >
        <Box>
          <Typography variant="h5" align="center">
            Choose a Widget
          </Typography>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Default Landing Page"
              component={RouterLink}
              to="/widgets/landing"
            />
            <Tab
              label="User Search"
              component={RouterLink}
              to="/widgets/user-search"
            />
            <Tab
              label="Featured Content"
              component={RouterLink}
              to="/widgets/featured-content"
            />
            <Tab
              label="Crossroads Prayers"
              component={RouterLink}
              to="/widgets/crossroads-prayers"
            />
            <Tab
              label="Home Tab Spotlight"
              component={RouterLink}
              to="/widgets/home-tab-spotlight"
            />
            <Tab
              label="Daily Scripture"
              component={RouterLink}
              to="widgets/daily-scripture"
            />
            <Tab
              label="Commitments"
              component={RouterLink}
              to="widgets/commitments"
            />
          </Tabs>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};
Sidebar.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Sidebar;
