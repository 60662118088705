import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const GuideProgressOptions = ({
  handleMetricChange,
  handleUnitChange,
  metric,
  unit,
}) => {
  return (
    <>
      <Typography variant="h4">Guide Progress</Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: 2,
        }}
      >
        <FormControl required>
          <InputLabel>Metric</InputLabel>
          <Select
            label="Metric"
            variant="outlined"
            sx={{ flexGrow: 2 }}
            value={metric}
            onChange={handleMetricChange}
            required
          >
            <MenuItem value={"level"}>Level</MenuItem>
            <MenuItem value={"section"}>Heading</MenuItem>
          </Select>
        </FormControl>

        <FormControl required>
          <InputLabel>Unit</InputLabel>
          <Select
            label="Metric"
            variant="outlined"
            sx={{ flexGrow: 2 }}
            value={unit}
            onChange={handleUnitChange}
          >
            <MenuItem value={"Day"}>Day</MenuItem>
            <MenuItem value={"Level"}>Level</MenuItem>
            <MenuItem value={"Week"}>Week</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

GuideProgressOptions.propTypes = {
  handleMetricChange: PropTypes.func.isRequired,
  handleUnitChange: PropTypes.func.isRequired,
  metric: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default GuideProgressOptions;
