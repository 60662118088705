import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import restService from "../../services/restService";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const contentTypes = [
  "article",
  "message",
  "appTip",
  "promo",
  "episode",
  "videoShort",
];

const SearchBar = ({ onSearch, searchQuery, setSearchQuery }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <TextField
        label="Title"
        variant="outlined"
        sx={{ flexGrow: 5 }}
        value={searchQuery.title}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, title: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <Select
        label="Content Type"
        variant="outlined"
        sx={{ flexGrow: 2 }}
        value={
          searchQuery.contentTypes.length > 1
            ? null
            : searchQuery.contentTypes[0]
        }
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, contentTypes: [event.target.value] })
        }
      >
        {contentTypes.map((ct) => (
          <MenuItem key={`contentType_${ct}`} value={ct}>
            {capitalize(ct)}
          </MenuItem>
        ))}
      </Select>
      <Button variant="contained" sx={{ flexGrow: 1 }} onClick={onSearch}>
        Search
      </Button>
    </Box>
  );
};
SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.shape({
    title: PropTypes.string,
    contentTypes: PropTypes.arrayOf(PropTypes.string),
  }),
  setSearchQuery: PropTypes.func.isRequired,
};

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 1,
  setCurrentPage,
  pageSize,
  setPageSize,
  setContentState,
}) => {
  const columns = [
    {
      field: "contentId",
      headerName: "Id",
      flex: 1,
      valueGetter: (params) => params.row._id,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      valueGetter: (params) => params.row.fields?.title,
    },
    {
      field: "type",
      headerName: "Content Type",
      flex: 2,
      valueGetter: (params) => capitalize(params.row.type),
    },
    {
      field: "publishedAt",
      headerName: "Published At",
      flex: 2,
      valueGetter: (params) => params.row.fields?.published_at,
    },
    {
      field: "featuredIndex",
      headerName: "Featured Index",
      flex: 2,
      valueGetter: (params) => params.row.featuredIndex,
    },
    {
      field: "Edit Content",
      headerName: "Edit Content",
      flex: 1,
      renderCell: (params) => (
        <>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              setContentState({
                selectedContent: params.row,
                showEditContentModal: true,
              })
            }
          />
        </>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: "center",
    align: "center",
  }));

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: 0,
          pageSize: pageSize,
        }}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.date,
      }),
      featuredIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setContentState: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/display-name
const SearchForContent = forwardRef(
  ({ setContentState, featuredContentOnly }, ref) => {
    useImperativeHandle(ref, () => ({
      refreshData: () => handleSearch(),
    }));
    const [searchQuery, setSearchQuery] = useState({
      title: "",
      contentTypes: [...contentTypes],
    });

    const [contentFound, setContentFound] = useState([]);
    // eslint-disable-next-line no-magic-numbers
    const [pageSize, setPageSize] = useState(5);
    const [totalRowCount, setTotalRowCount] = useState(0);

    useEffect(() => {
      if (featuredContentOnly) {
        fetchData();
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = async (size) => {
      if (featuredContentOnly) {
        return restService
          .callEndpoint({ endpoint: "getFeaturedContent", method: "get" })
          .then((res) => {
            setContentFound(...[get(res, "data", [])]);
            setTotalRowCount(get(res, "data.length", 0));
          });
      }

      let validSearchValues = Object.fromEntries(
        Object.entries(searchQuery).filter(([_, v]) => v !== "")
      );
      if (Object.keys(validSearchValues).length) {
        const body = { query: validSearchValues, pageSize: size };
        restService
          .callEndpoint({ endpoint: "searchContent", body, method: "post" })
          .then((data) => {
            setContentFound(...[get(data, "content", [])]);
            setTotalRowCount(get(data, "totalCount", 0));
          });
      }
    };

    const handleSearch = () => {
      fetchData(pageSize);
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {!featuredContentOnly && (
          <SearchBar
            onSearch={handleSearch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        )}
        {featuredContentOnly && contentFound.length === 0 && (
          <p>
            No featured content set.
            <br />
            Search for content below to select content to be featured.
          </p>
        )}
        {contentFound.length > 0 && (
          <SearchResults
            rows={contentFound}
            totalRowCount={totalRowCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setContentState={setContentState}
          />
        )}
      </Box>
    );
  }
);
SearchForContent.propTypes = {
  setContentState: PropTypes.func.isRequired,
  featuredContentOnly: PropTypes.bool,
};

SearchForContent.defaultProps = {
  featuredContentOnly: false,
};

export default SearchForContent;
