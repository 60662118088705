import { Button, Link } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import PropTypes from "prop-types";

const displayGuideProgressMetric = (metric) => {
  switch (metric) {
    case "section":
      return "Heading";
    case "level":
      return "Level";
    default:
      return "";
  }
};

const SearchResults = ({ rows, totalRowCount, deleteContent }) => {
  const columns = [
    {
      field: "linkedId",
      headerName: "Linked Content Id",
      flex: 1,
      valueGetter: (params) => params.row.linkedContent._id,
    },
    {
      field: "linkedType",
      headerName: "Linked Content Type",
      flex: 1,
      valueGetter: (params) => params.row.linkedContent.type,
    },
    {
      field: "linkedTitle",
      headerName: "Linked Content Title",
      flex: 2,
      valueGetter: (params) => params.row.linkedContent.title,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) => new Date(params.row.createdAt).toDateString(),
    },
    {
      field: "heading",
      headerName: "Heading",
      flex: 1,
      valueGetter: (params) => params.row.heading,
    },
    {
      field: "subheading",
      headerName: "Subheading",
      flex: 1,
      valueGetter: (params) => params.row.subheading,
    },
    {
      field: "buttonText",
      headerName: "Button Text",
      flex: 1,
      valueGetter: (params) => params.row.buttonText,
    },
    {
      field: "progressMetric",
      headerName: "Progress Metric",
      flex: 1,
      valueGetter: (params) =>
        displayGuideProgressMetric(params.row.guideProgress?.metric),
    },
    {
      field: "progressUnit",
      headerName: "Progress Unit",
      flex: 1,
      valueGetter: (params) => params.row.guideProgress?.unit,
    },
    {
      field: "backgroundImage",
      headerName: "Background Image",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="text"
          onClick={() => window.open(params.row.backgroundImage, "_blank")}
        >
          View Image
        </Button>
      ),
    },
    {
      field: "url",
      headerName: "URL",
      flex: 1,
      renderCell: (params) => (
        <Link href={params.row.url}>{params.row.url}</Link>
      ),
    },
    {
      field: "startOn",
      headerName: "Start On",
      flex: 1,
      valueGetter: (params) =>
        params.row?.startOn ? new Date(params.row.startOn).toString() : "",
    },
    {
      field: "endOn",
      headerName: "End On",
      flex: 1,
      valueGetter: (params) =>
        params.row?.endOn ? new Date(params.row.endOn).toString() : "",
    },
    {
      field: "delete",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => deleteContent(params.row._id)}
            variant="contained"
          >
            Delete
          </Button>
        </>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: "center",
    align: "center",
  }));

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        disableColumnFilter
        disableRowSelectionOnClick
        hideFooter
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      prayerText: PropTypes.string,
      createdAt: PropTypes.string,
      prayedForCount: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  deleteContent: PropTypes.func.isRequired,
};

export default SearchResults;
