import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { get } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import restService from "../../services/restService";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const contentTypes = ["guide"];

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const SearchBar = ({ onSearch, searchQuery, setSearchQuery }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <TextField
        label="Title"
        variant="outlined"
        sx={{ flexGrow: 5 }}
        value={searchQuery.title}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, title: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <Select
        label="Content Type"
        variant="outlined"
        sx={{ flexGrow: 2 }}
        value={
          searchQuery.contentTypes.length > 1
            ? null
            : searchQuery.contentTypes[0]
        }
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, contentTypes: [event.target.value] })
        }
      >
        {contentTypes.map((ct) => (
          <MenuItem key={`contentType_${ct}`} value={ct}>
            {capitalize(ct)}
          </MenuItem>
        ))}
      </Select>
      <Button variant="contained" sx={{ flexGrow: 1 }} onClick={onSearch}>
        Search
      </Button>
    </Box>
  );
};
SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.shape({
    title: PropTypes.string,
    contentTypes: PropTypes.arrayOf(PropTypes.string),
  }),
  setSearchQuery: PropTypes.func.isRequired,
};

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 1,
  setCurrentPage,
  pageSize,
  setPageSize,
  setContent,
  closeModal,
}) => {
  const selectContent = (content) => {
    setContent(content);
    closeModal();
  };

  const columns = [
    {
      field: "contentId",
      headerName: "Id",
      flex: 1,
      valueGetter: (params) => params.row._id,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      valueGetter: (params) => params.row.fields?.title,
    },
    {
      field: "type",
      headerName: "Content Type",
      flex: 1,
      valueGetter: (params) => capitalize(params.row.type),
    },
    {
      field: "publishedAt",
      headerName: "Published At",
      flex: 1,
      valueGetter: (params) => params.row.fields?.published_at,
    },
    {
      field: "select",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button onClick={() => selectContent(params.row)} variant="contained">
            Select
          </Button>
        </>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: "center",
    align: "center",
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1); // Add 1 to convert to one-based numbering
    setPageSize(paginationModel.pageSize);
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage - 1,
          pageSize: pageSize,
        }}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.string,
      }),
      featuredIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/display-name
const SearchForContent = ({ setContent, closeModal }) => {
  const [searchQuery, setSearchQuery] = useState({
    title: "",
    contentTypes: [...contentTypes],
  });

  const [contentFound, setContentFound] = useState([]);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);
  const prevPageSize = usePrevious(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const prevCurrentPage = usePrevious(currentPage);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const fetchData = useCallback(async ({ page, size, query }) => {
    let validSearchValues = Object.fromEntries(
      Object.entries(query).filter(([_, v]) => v !== "")
    );
    if (Object.keys(validSearchValues).length) {
      const body = { query: validSearchValues, page, pageSize: size };
      restService
        .callEndpoint({ endpoint: "searchContent", body, method: "post" })
        .then((data) => {
          setContentFound(...[get(data, "content", [])]);
          setTotalRowCount(get(data, "totalCount", 0));
        });
    }
  }, []);

  const handleSearch = () => {
    fetchData({ page: currentPage, size: pageSize, query: searchQuery });
  };

  useEffect(() => {
    if (
      (prevPageSize && pageSize && prevPageSize !== pageSize) ||
      (prevCurrentPage && currentPage && prevCurrentPage !== currentPage)
    ) {
      fetchData({ page: currentPage, size: pageSize, query: searchQuery });
    }
  }, [
    currentPage,
    fetchData,
    pageSize,
    prevCurrentPage,
    prevPageSize,
    searchQuery,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SearchBar
        onSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      {contentFound.length > 0 && (
        <SearchResults
          rows={contentFound}
          totalRowCount={totalRowCount}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setContent={setContent}
          closeModal={closeModal}
        />
      )}
    </Box>
  );
};

SearchForContent.propTypes = {
  setContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const SearchModal = ({ open, handleModalClose, setContent }) => {
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(15px)" }}
    >
      <Box
        sx={{
          position: "absolute",
          flex: 1,
          padding: "2rem",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "70%",
          bgcolor: "background.paper",
          borderRadius: "2rem",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "text.primary",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          Search for Content
        </Typography>
        <SearchForContent
          setContent={setContent}
          closeModal={handleModalClose}
        />
      </Box>
    </Modal>
  );
};

SearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
};

export default SearchModal;
