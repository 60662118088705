import { Box, Button, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { isEmpty } from "lodash";

import PropTypes from "prop-types";
import { useState } from "react";
import restService from "../../services/restService";
import ImageUpload from "../ImageUpload";

import GuideProgressOptions from "./GuideProgressOptions";
import SelectContent from "./SelectContent";

const Spacer = () => <div style={{ height: "1rem" }} />;

const NewSpotlight = ({ refetchData }) => {
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [content, setContent] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [url, setUrl] = useState("");
  const [progressMetric, setProgressMetric] = useState("");
  const [progressUnit, setProgressUnit] = useState("");
  const [startOn, setStartOn] = useState(null);
  const [endOn, setEndOn] = useState(null);

  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };

  const handleSubheadingChange = (event) => {
    setSubheading(event.target.value);
  };

  const handleButtonTextChange = (event) => {
    setButtonText(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
    setContent({});
  };

  const handleProgressMetricChange = (event) => {
    setProgressMetric(event.target.value);
  };

  const handleProgressUnitChange = (event) => {
    setProgressUnit(event.target.value);
  };

  const handleStartOnChange = (event) => setStartOn(event);
  const handleEndOnChange = (event) => setEndOn(event);

  const handleCreateSpotlight = async () => {
    const type = url ? "url" : content.type;
    restService
      .callEndpoint({
        endpoint: "createSpotlight",
        body: {
          contentId: content._id,
          type,
          heading,
          subheading,
          buttonText,
          backgroundImage: imageUrl,
          url,
          startOn: startOn.toISOString(),
          endOn: endOn.toISOString(),
          ...(progressMetric ? { progressMetric } : {}),
          ...(progressUnit ? { progressUnit } : {}),
        },
        method: "post",
      })
      .then(() => {
        refetchData();
      });
  };

  const disableCreateButton =
    !imageUrl ||
    (isEmpty(content) && !url) ||
    (content.type === "guide" && !(progressMetric && progressUnit));

  const renderEmptyImageUrlMessage = () => (
    <Typography variant="body1">No image selected.</Typography>
  );

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 2,
          }}
        >
          <TextField
            label="Heading"
            value={heading}
            onChange={handleHeadingChange}
          />
          <TextField
            label="Subheading"
            value={subheading}
            onChange={handleSubheadingChange}
          />
          <TextField
            label="Button Text"
            value={buttonText}
            onChange={handleButtonTextChange}
          />
          <TextField
            required={!content._id}
            label="URL"
            value={url}
            onChange={handleUrlChange}
          />
          <DateTimePicker
            label="Start On"
            value={startOn}
            onChange={handleStartOnChange}
          />
          <DateTimePicker
            label="End On"
            value={endOn}
            onChange={handleEndOnChange}
          />
        </Box>
        <Spacer />
        <SelectContent
          required={!url}
          description="Linked content is automatically opened when the button on the spotlight
          is tapped. A URL above will override selected content."
          setContent={setContent}
          content={content}
          disabled={url}
        />
        <Spacer />
        {content.type === "guide" && (
          <GuideProgressOptions
            handleMetricChange={handleProgressMetricChange}
            handleUnitChange={handleProgressUnitChange}
            metric={progressMetric}
            unit={progressUnit}
          />
        )}
        <ImageUpload
          setImageUrl={setImageUrl}
          renderEmpty={renderEmptyImageUrlMessage}
          required
        />
      </Box>
      <Button
        variant="contained"
        size="large"
        sx={{ width: "100%" }}
        onClick={handleCreateSpotlight}
        disabled={disableCreateButton}
      >
        Create Spotlight
      </Button>
    </>
  );
};

NewSpotlight.propTypes = {
  refetchData: PropTypes.func.isRequired,
};

export default NewSpotlight;
