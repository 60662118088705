import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppContentWrapper from "./components/AppContentWrapper";
import AppHeader from "./components/AppHeader";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import NoMatch from "./components/NoMatch";
import restService from "./services/restService";
import Commitments from "./widgets/Commitments";
import CrossroadsPrayers from "./widgets/CrossroadsPrayers";
import DailyScripture from "./widgets/DailyScripture";
import FeaturedContent from "./widgets/FeaturedContent";
import HomeTabSpotlight from "./widgets/HomeTabSpotlight";
import Landing from "./widgets/Landing";
import UserSearch from "./widgets/UserSearch";

const Layout = ({ setIsAuthenticated }) => {
  return (
    <AppContentWrapper setIsAuthenticated={setIsAuthenticated}>
      <Outlet />
    </AppContentWrapper>
  );
};
Layout.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
};

const widgets = [
  { routePath: "user-search", Component: () => <UserSearch /> },
  { routePath: "featured-content", Component: () => <FeaturedContent /> },
  { routePath: "crossroads-prayers", Component: () => <CrossroadsPrayers /> },
  { routePath: "home-tab-spotlight", Component: () => <HomeTabSpotlight /> },
  { routePath: "daily-scripture", Component: () => <DailyScripture /> },
  { routePath: "commitments", Component: () => <Commitments /> },
];

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    restService.isAuthTokenValid(setIsAuthenticated);
  }, [isAuthenticated]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
        }}
      >
        <AppHeader />
        {isAuthenticated ? (
          <Navigation
            LoginPage={Login}
            Layout={Layout}
            DefaultLanding={Landing}
            NoMatch={NoMatch}
            widgets={widgets}
            setIsAuthenticated={setIsAuthenticated}
          />
        ) : (
          <Login setIsAuthenticated={setIsAuthenticated} />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default App;
