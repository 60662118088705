import PropTypes from "prop-types";
import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

const Navigation = ({
  Layout,
  DefaultLanding,
  NoMatch,
  widgets,
  setIsAuthenticated,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout setIsAuthenticated={setIsAuthenticated} />}
      >
        <Route path="widgets/" element={<Outlet />}>
          <Route index path="landing" element={<DefaultLanding />} />
          {widgets.map((widget) => {
            const { routePath, Component } = widget;
            return (
              <Route key={routePath} path={routePath} element={<Component />} />
            );
          })}
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
Navigation.propTypes = {
  Layout: PropTypes.elementType.isRequired,
  DefaultLanding: PropTypes.elementType.isRequired,
  NoMatch: PropTypes.elementType.isRequired,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      routePath: PropTypes.string.isRequired,
      Component: PropTypes.func.isRequired,
    })
  ),
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Navigation;
