import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect, forwardRef } from "react";
import restService from "../../services/restService";
import SearchCommitments from "../commitments/SearchCommitments";

const columns = [
  {
    field: "createdAt",
    headerName: "Pledged On",
    flex: 1,
    valueGetter: (params) => params.row.createdAt,
    exportGetter: (params) => params.createdAt,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    valueGetter: (params) => params.row.firstName,
    exportGetter: (params) => params.firstName,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    valueGetter: (params) => params.row.lastName,
    exportGetter: (params) => params.lastName,
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    valueGetter: (params) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.row.amount || 0),
    exportGetter: (params) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.amount || 0),
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    valueGetter: (params) => params.row.type,
    exportGetter: (params) => params.type,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    valueGetter: (params) => params.row.email,
    exportGetter: (params) => params.email,
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    valueGetter: (params) => params.row.phone,
    exportGetter: (params) => params.phone,
  },
  {
    field: "street1",
    headerName: "Street 1",
    flex: 1,
    valueGetter: (params) => params.row.address.street1,
    exportGetter: (params) => params.address.street1,
  },
  {
    field: "street2",
    headerName: "Street 2",
    flex: 1,
    valueGetter: (params) => params.row.address.street2,
    exportGetter: (params) => params.address.street2,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    valueGetter: (params) => params.row.address.city,
    exportGetter: (params) => params.address.city,
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
    valueGetter: (params) => params.row.address.state,
    exportGetter: (params) => params.address.state,
  },
  {
    field: "postalCode",
    headerName: "Postal Code",
    flex: 1,
    valueGetter: (params) => params.row.address.postalCode,
    exportGetter: (params) => params.address.postalCode,
  },
  {
    field: "signed",
    headerName: "Signed",
    flex: 1,
    valueGetter: (params) => params.row.signed,
    exportGetter: (params) => params.signed,
  },
  {
    field: "rockPledgeId",
    headerName: "Rock Pledge Id",
    flex: 1,
    valueGetter: (params) => params.row.rockPledgeId,
    exportGetter: (params) => params.rockPledgeId,
  },
  {
    field: "story",
    headerName: "Story",
    flex: 1,
    valueGetter: (params) => params.row.story,
    exportGetter: (params) => params.story,
  },
].map((column) => ({
  ...column,
  sortable: false,
  headerAlign: "center",
  align: "center",
}));

const SearchResults = ({ rows, totalRowCount, pageSize }) => {
  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: 0,
          pageSize: pageSize,
        }}
        getRowHeight={() => "auto"}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.date,
      }),
      featuredIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setContentState: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/display-name
const CommitmentsTable = forwardRef((_, ref) => {
  const [search, setSearch] = useState({
    email: "",
    firstName: "",
    lastName: "",
    notInRock: false,
  });
  const [commitments, setCommitments] = useState([]);

  const handleSearch = () => {
    fetchData(search);
  };

  const getExportData = {
    headers: columns.map((column) => column.headerName),
    data: commitments.map((commitment) =>
      columns.map((column) => column.exportGetter(commitment))
    ),
  };

  const fetchData = async (searchQuery) => {
    let validSearchValues = Object.fromEntries(
      Object.entries(searchQuery).filter(([_, v]) => v !== "")
    );
    const response = await restService.callEndpoint({
      endpoint: "getCommitmentList",
      body: {
        query: validSearchValues,
      },
      method: "post",
    });
    const commitmentsResult = response;
    await setCommitments(commitmentsResult);
  };

  useEffect(() => {
    if (isEmpty(commitments)) {
      fetchData(search);
    }
  }, [commitments, search]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SearchCommitments
        onSearch={handleSearch}
        searchQuery={search}
        setSearchQuery={setSearch}
        exportData={getExportData}
        handleRefresh={handleSearch}
      />
      {!isEmpty(commitments) ? (
        <SearchResults
          rows={commitments}
          totalRowCount={commitments.length}
          pageSize={commitments.length}
          setCurrentPage={() => 0}
        />
      ) : (
        <Alert variant="outlined" severity="info">
          There are no commitments yet.
        </Alert>
      )}
    </Box>
  );
});

CommitmentsTable.propTypes = {};

CommitmentsTable.defaultProps = {};

export default CommitmentsTable;
