import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

import { Box, IconButton, Typography } from "@mui/material";

import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import restService from "../../services/restService";

import CreateSpotlight from "./CreateSpotlight";
import SpotlightSearchResults from "./SpotlightSearchResults";

const SearchForPrayers = () => {
  const [spotlightFound, setSpotlightFound] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const fetchData = async () => {
    restService
      .callEndpoint({ endpoint: "getSpotlight", method: "get" })
      .then((data) => {
        const result = get(data, "data", []);
        setSpotlightFound(isEmpty(result) ? [] : [result]);

        setTotalRowCount(get(result, "length", 0));
      });
  };

  const handleDeleteSpotlight = async (id) => {
    restService
      .callEndpoint({
        endpoint: "updateSpotlight",
        body: {
          _id: id,
          update: {
            delete: true,
          },
        },
        method: "post",
      })
      .then(() => {
        fetchData();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "1rem",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Home Tab Spotlight</Typography>
        <IconButton onClick={fetchData}>
          <RefreshRoundedIcon color="primary" />
        </IconButton>
      </div>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {spotlightFound.length > 0 ? (
          <SpotlightSearchResults
            totalRowCount={totalRowCount}
            rows={spotlightFound}
            deleteContent={handleDeleteSpotlight}
          />
        ) : (
          <CreateSpotlight refetchData={fetchData} />
        )}
      </Box>
    </>
  );
};

export default SearchForPrayers;
