import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import restService from "../../services/restService";
import formatPassage from "./formatPassage";

const SearchResults = ({ rows, totalRowCount, pageSize }) => {
  const columns = [
    {
      field: "activeAt",
      headerName: "Active At",
      flex: 1,
      valueGetter: (params) => params.row.activeAt,
    },
    {
      field: "passage",
      headerName: "Passage",
      flex: 1,
      valueGetter: (params) => params.row.passage,
    },
    {
      field: "prompt",
      headerName: "Prompt",
      flex: 5,
      valueGetter: (params) => params.row.prompt,
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: "center",
    align: "center",
  }));

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: 0,
          pageSize: pageSize,
        }}
        getRowHeight={() => "auto"}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      fields: PropTypes.shape({
        title: PropTypes.string,
        published_at: PropTypes.date,
      }),
      featuredIndex: PropTypes.number,
    })
  ),
  totalRowCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  setContentState: PropTypes.func.isRequired,
};

const formatDailyScripture = (row) => {
  const book = get(row, "book", "");
  const chapter = get(row, "chapter", "");
  const startVerse = get(row, "startVerse", "");
  const endVerse = get(row, "endVerse", "");
  const passage = formatPassage({ book, chapter, startVerse, endVerse });
  return {
    _id: get(row, "_id", ""),
    activeAt: moment(get(row, "activeAt", "")).format("MM/DD/YYYY"),
    passage,
    prompt: get(row, "prompt", ""),
  };
};

// eslint-disable-next-line react/display-name
const DailyScriptureTable = forwardRef(({ setLastDate = () => {} }, ref) => {
  useImperativeHandle(ref, () => ({
    refreshData: fetchData,
  }));

  const [dailyScriptures, setDailyScriptures] = useState([]);

  const fetchData = async () => {
    const response = await restService.callEndpoint({
      endpoint: "getScriptureList",
      body: {
        date: moment().utc().toDate(),
      },
      method: "post",
    });
    const dailyScripturesResult = response.map((s) => formatDailyScripture(s));
    await setDailyScriptures(dailyScripturesResult);
    const lastDailyScriptureDate =
      dailyScripturesResult[dailyScripturesResult.length - 1].activeAt;
    setLastDate(lastDailyScriptureDate);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {dailyScriptures.length > 0 && (
        <SearchResults
          rows={dailyScriptures}
          totalRowCount={dailyScriptures.length}
          pageSize={dailyScriptures.length}
          setCurrentPage={() => 0}
        />
      )}
    </Box>
  );
});

DailyScriptureTable.propTypes = {
  setLastDate: PropTypes.func,
};

DailyScriptureTable.defaultProps = {};

export default DailyScriptureTable;
