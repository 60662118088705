const Landing = () => {
  return (
    <div>
      <h2>Default Landing Page</h2>
      <p>Welcome Dashboard User! You are now logged in.</p>
    </div>
  );
};

export default Landing;
