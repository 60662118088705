import ArchiveIcon from "@mui/icons-material/ArchiveRounded";
import CancelIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import SaveIcon from "@mui/icons-material/SaveRounded";
import AnswerIcon from "@mui/icons-material/SpellcheckRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { cloneDeep, get, isEmpty, noop } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import restService from "../services/restService";
import ImageUpload, { DEFAULT_IMAGE_URL } from "./ImageUpload";

function SearchResults({
  rows,
  totalRowCount,
  setState,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  onPaginationChange = noop,
}) {
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleArchiveClick = (id) => () => {
    setState({
      modalOpen: true,
      prayerId: id,
      action: "archive",
    });
  };

  const handleAnswerClick = (id) => () => {
    setState({
      modalOpen: true,
      prayerId: id,
      action: "answer",
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const getChangedRows = (newRow) => {
    const allFields = cloneDeep(newRow);
    delete allFields.prayer;
    delete allFields.spotlight;
    const spotlightFields = ["heading", "subheading", "buttonText"];
    const prayerFields = ["prayerText"];
    const updatedFields = {};
    Object.keys(allFields).forEach((key) => {
      if (spotlightFields.includes(key)) {
        if (newRow.spotlight[key] !== allFields[key]) {
          updatedFields[key] = allFields[key];
        }
      } else if (prayerFields.includes(key)) {
        if (newRow.prayer[key] !== allFields[key]) {
          updatedFields[key] = allFields[key];
        }
      }
    });
    return updatedFields;
  };

  const processRowUpdate = (newRow) => {
    const updatedFields = getChangedRows(newRow);
    if (!isEmpty(updatedFields)) {
      setState({
        modalOpen: true,
        prayerId: newRow.prayer._id,
        newData: updatedFields,
        action: "update",
      });
    }

    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const renderViewImage = (params) => (
    <Button
      component="a"
      href={get(params, "row.spotlight.backgroundImage")}
      target="_blank"
    >
      View Image
    </Button>
  );

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(
      paginationModel.pageSize === pageSize ? paginationModel.page : 0
    );

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  const columns = [
    {
      field: "prayerText",
      headerName: "Prayer Text",
      editable: true,
      flex: 3,
      valueGetter: (params) => get(params, "row.prayer.prayerText", ""),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) =>
        params.row.prayer.createdAt
          ? new Date(params.row.prayer.createdAt).toDateString()
          : "",
    },
    {
      field: "prayedForCount",
      headerName: "Prayed For Count",
      flex: 1,
      valueGetter: (params) => get(params, "row.prayer.prayedForCount", 0),
    },
    {
      field: "heading",
      editable: true,
      headerName: "Heading",
      flex: 1,
      valueGetter: (params) => get(params, "row.spotlight.heading", ""),
    },
    {
      field: "subheading",
      headerName: "Subheading",
      editable: true,
      flex: 1,
      valueGetter: (params) => get(params, "row.spotlight.subheading", ""),
    },
    {
      field: "buttonText",
      headerName: "Button Text",
      editable: true,
      flex: 1,
      valueGetter: (params) => get(params, "row.spotlight.buttonText", ""),
    },
    {
      field: "backgroundImage",
      headerName: "Background",
      align: "left",
      flex: 1,
      renderCell: (params) => renderViewImage(params),
    },
    {
      field: "startOn",
      headerName: "Start On",
      flex: 1,
      valueGetter: (params) =>
        params.row.spotlight?.startOn
          ? new Date(params.row.spotlight.startOn).toString()
          : "",
    },
    {
      field: "endOn",
      headerName: "End On",
      flex: 1,
      valueGetter: (params) =>
        params.row.spotlight?.endOn
          ? new Date(params.row.spotlight.endOn).toString()
          : "",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              key="save"
              title="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              key="cancel"
              title="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key="edit"
            title="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ArchiveIcon />}
            label="Archive"
            title="Archive"
            key="archive"
            onClick={handleArchiveClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<AnswerIcon />}
            label="Answer"
            title="Answer"
            key="answer"
            onClick={handleAnswerClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        paginationMode="server"
        paginationModel={{
          page: currentPage, // DataGrid uses zero-based page numbering
          pageSize,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        rowCount={totalRowCount}
        getRowId={(row) => row.prayer._id}
        disableColumnFilter
      />
    </Box>
  );
}

SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      prayerText: PropTypes.string,
      createdAt: PropTypes.string,
      prayedForCount: PropTypes.number,
    })
  ),
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  setState: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

const NewPrayer = ({ refetchPrayers, onComplete }) => {
  const [prayerText, setPrayerText] = useState("");
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [startOn, setStartOn] = useState(null);
  const [endOn, setEndOn] = useState(null);

  const handlePrayerTextChange = (event) => {
    setPrayerText(event.target.value);
  };

  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };

  const handleSubheadingChange = (event) => {
    setSubheading(event.target.value);
  };

  const handleButtonTextChange = (event) => {
    setButtonText(event.target.value);
  };

  const handleStartOnChange = (event) => setStartOn(event);
  const handleEndOnChange = (event) => setEndOn(event);

  const handleCreatePrayer = async () => {
    restService
      .callEndpoint({
        endpoint: "createCrossroadsPrayer",
        body: {
          prayerText,
          heading,
          subheading,
          buttonText,
          imageUrl: imageUrl || DEFAULT_IMAGE_URL,
          startOn,
          endOn,
        },
        method: "post",
      })
      .then(() => {
        refetchPrayers();
        onComplete();
      });
  };

  const disableCreateButton = !prayerText || !heading;

  const renderEmptyImageUrlMessage = () => (
    <Typography variant="body1">
      <Typography variant="body1">
        {
          'No image selected. Click "Upload Image" below. If you do not select an image we\'ll use'
        }
      </Typography>
      <Link target="_blank" rel="noopener" href={DEFAULT_IMAGE_URL}>
        {" this default image."}
      </Link>
    </Typography>
  );

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: 2,
        }}
      >
        <TextField
          multiline
          fullWidth
          required
          label="Prayer Text"
          value={prayerText}
          onChange={handlePrayerTextChange}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 2,
          }}
        >
          <TextField
            required
            label="Heading"
            value={heading}
            onChange={handleHeadingChange}
          />
          <TextField
            label="Subheading"
            value={subheading}
            onChange={handleSubheadingChange}
          />
          <TextField
            label="Button Text"
            value={buttonText}
            onChange={handleButtonTextChange}
          />
          <DateTimePicker
            label="Start On"
            value={startOn}
            onChange={handleStartOnChange}
          />
          <DateTimePicker
            label="End On"
            value={endOn}
            onChange={handleEndOnChange}
          />
        </Box>
        <ImageUpload
          setImageUrl={setImageUrl}
          renderEmpty={renderEmptyImageUrlMessage}
        />
      </Box>
      <Button
        variant="contained"
        size="large"
        sx={{ width: "100%" }}
        onClick={handleCreatePrayer}
        disabled={disableCreateButton}
      >
        Create Prayer
      </Button>
    </>
  );
};

NewPrayer.propTypes = {
  onComplete: PropTypes.func,
  refetchPrayers: PropTypes.func,
};

const defaultState = {
  modalOpen: false,
  prayerId: null,
  newData: {},
  action: null,
};

const SearchForPrayers = () => {
  const [prayersFound, setPrayersFound] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [state, setState] = useState(defaultState);
  const [showCreate, setShowCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationChaged, setPaginationChanged] = useState(false);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);

  const handleModalClose = () => {
    setState(defaultState);
  };

  const handleUpdatePrayer = ({ action, prayerId }) => {
    if (action === "update" && (!state.newData || isEmpty(state.newData))) {
      return;
    }
    restService
      .callEndpoint({
        endpoint: "updateCrossroadsPrayer",
        body: { action, prayerId, updatedFields: state.newData },
        method: "post",
      })
      .then(() => {
        fetchData();
        handleModalClose();
      });
  };

  const fetchData = useCallback(() => {
    restService
      .callEndpoint({
        endpoint: "getCrossroadsPrayer",
        params: { pageSize, page: currentPage },
        method: "get",
      })
      .then((data) => {
        const result = get(data, "data.prayers");
        setPrayersFound(result || []);
        setTotalRowCount(get(data, "data.totalCount", 0));
        setPaginationChanged(false);
      });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (paginationChaged) {
      fetchData();
    }
  }, [fetchData, paginationChaged]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal open={state.modalOpen}>
        <Box>
          <Dialog
            open={state.modalOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Update Prayer"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {state.action} this prayer?
              </DialogContentText>
              {!isEmpty(state.newData) ? (
                <DialogContentText sx={{ margin: "1rem 0 0 0" }}>
                  The following fields will be updated:
                  {Object.keys(state.newData).map((k) => (
                    <DialogContentText key={k}>{`    ${k}`}</DialogContentText>
                  ))}
                </DialogContentText>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Go Back</Button>
              <Button
                onClick={() => {
                  handleUpdatePrayer({
                    action: state.action,
                    prayerId: state.prayerId,
                  });
                }}
                autoFocus
              >
                {state.action} Prayer
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          margin: "0 0 2rem 0",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Crossroads Prayers</Typography>
        <IconButton onClick={fetchData} title="Refresh">
          <RefreshRoundedIcon color="primary" fontSize="large" />
        </IconButton>
        {showCreate ? (
          <Button variant="contained" onClick={() => setShowCreate(false)}>
            Show List
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setShowCreate(true)}>
            Create
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {showCreate ? (
          <NewPrayer
            refetchPrayers={fetchData}
            onComplete={() => setShowCreate(false)}
          />
        ) : (
          <SearchResults
            rows={prayersFound}
            totalRowCount={totalRowCount}
            setState={setState}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onPaginationChange={() => setPaginationChanged(true)}
          />
        )}
      </Box>
    </>
  );
};

export default SearchForPrayers;
