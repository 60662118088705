/* eslint react/prop-types: 0 */
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { cloneDeep, get, set } from "lodash";
import * as React from "react";
import { useState } from "react";
import restService from "../../services/restService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70%",
  bgcolor: "white",
  border: "2px solid #000",
  p: 4,
};

const RenderFields = ({ state, setState, content }) => {
  // eslint-disable-next-line no-empty-pattern
  const { updatedContent, modifiedFields = [] } = state;
  const readOnlyFields = {
    Title: "fields.title",
    Type: "type",
    "Published At": "fields.published_at",
    "Content Id": "_id",
  };

  const fields = {
    "Featured Index": "featuredIndex",
  };

  const checkModifiedFields = (value, field) => {
    if (field === "featuredIndex") {
      if (modifiedFields.indexOf(field) !== -1) {
        return modifiedFields.filter(
          (modifiedField, index) => index !== modifiedFields.indexOf(field)
        );
      }
    }
    if (
      value === get(content, fields[field]) &&
      modifiedFields.indexOf(field) !== -1
    ) {
      return modifiedFields.filter(
        (modifiedField, index) => index !== modifiedFields.indexOf(field)
      );
    } else if (
      value !== get(content, fields[field]) &&
      modifiedFields.indexOf(field) === -1
    ) {
      return [...modifiedFields, field];
    }
    return modifiedFields;
  };

  return (
    <>
      {Object.keys(readOnlyFields).map((field, index) => (
        <div key={index} style={{ margin: "15px" }}>
          <Typography variant="h6" component="h6">
            {`${field}:`}
          </Typography>
          <Typography variant="body1" component="body1">
            {get(content, readOnlyFields[field])}
          </Typography>
        </div>
      ))}
      <div style={{ margin: "60px 0 15px 15px" }}>
        {Object.keys(fields).map((field, index) => (
          <TextField
            id="outlined-basic"
            label={field}
            value={get(updatedContent, fields[field])}
            onChange={(e) => {
              setState({
                ...state,
                updatedContent: set(
                  updatedContent,
                  fields[field],
                  e.target.value
                ),
                modifiedFields: checkModifiedFields(e.target.value, field),
              });
            }}
            variant="outlined"
            key={index}
            sx={{
              width: "300px",
              borderRadius: "5px",
              borderColor: "blue",
              backgroundColor: "#eee",
              input: { color: "black" },
              label: {
                color: modifiedFields.indexOf(field) === -1 ? "black" : "red",
                fontSize: 20,
                margin: "none",
                fontWeight: 600,
              },
              // margin: "15px",
            }}
          />
        ))}
      </div>
    </>
  );
};

// eslint-disable-next-line react/prop-types
export default function EditContent({ content, clearSelectedContent }) {
  const handleClose = () => clearSelectedContent();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [state, setState] = useState({
    updatedContent: cloneDeep(content),
    modifiedFields: [],
  });

  const handleSaveModalOpen = () => {
    setSaveModalOpen(true);
  };

  const handleSaveModalClose = () => {
    setSaveModalOpen(false);
  };

  const handleUpdateContent = () => {
    restService
      .callEndpoint({
        endpoint: "updateContent",
        body: {
          newContentDoc: state.updatedContent,
          modifiedFields: state.modifiedFields,
          oldContentDoc: content,
        },
        method: "put",
      })
      .then((data) => {
        if (data) {
          handleClose();
        } else {
          console.log("Error, try again later!"); // eslint-disable-line no-console
        }
      });
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              float: "right",
              fontSize: 15,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            X
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            {"Edit Content Item"}
          </Typography>
          <RenderFields state={state} setState={setState} content={content} />
          <Button
            variant="contained"
            onClick={handleSaveModalOpen}
            style={{
              position: "absolute",
              bottom: 40,
              right: 40,
              fontSize: 20,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            disabled={state.modifiedFields.length ? false : true}
          >
            Save Content
          </Button>
          <Dialog
            open={saveModalOpen}
            onClose={handleSaveModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Update Content"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The following fields have been modified:
                {state.modifiedFields.map((field) => (
                  <p style={{ margin: "0px" }} key={field}>
                    {field}
                  </p>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSaveModalClose}>Go Back</Button>
              <Button onClick={handleUpdateContent} autoFocus>
                Update Content
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
    </div>
  );
}
