import { Button } from "@mui/material";
import PropTypes from "prop-types";

const AddDailyScriptureButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      size="large"
      onClick={onClick}
      sx={{ alignSelf: "flex-start" }}
    >
      Add New Reading
    </Button>
  );
};

AddDailyScriptureButton.propTypes = {
  onClick: PropTypes.func,
};

export default AddDailyScriptureButton;
